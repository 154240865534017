import Layout from "@/layout";
import * as React from "react";
import { Home } from "@/components";
import SEO from "@/components/seo";
import { useLocation } from '@reach/router';

const IndexPage = () => {
  const {host} = useLocation();
  const canonical = `${host}`;
  return (
    <Layout>
      <SEO title="Home" canonical={canonical}/>
      <Home />
    </Layout>
  );
};

export default IndexPage;
